/* @media (max-width: 1023px) {
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
} */

/* font */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.notosanskr * {
  font-family: 'Noto Sans KR', sans-serif;
}

@import url(//fonts.googleapis.com/earlyaccess/nanummyeongjo.css);

/* nomal */
body,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Noto Sans KR', sans-serif;
}

body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

/* library */
.bg-red {
  background-color: red;
}

.bg-gold {
  background-color: gold;
}

.bg-gray {
  background-color: gray;
}

.bg-gray {
  background: #f5f6f9;
}

/* custom */
:root {
  --header-width: 1400px;
  --header-height: 80px;
  --header-mobile-height: 50px;
  --detail-width: 1200px;
  --detail-right-width: 350px;
  --slick-height: 500px;
}

@media (max-width: 1023px) {
  :root {
    --header-height: 60px;
  }
}

:root {

  --page-width: 1200px;
  --page-padding-pc: 0 40px;
  --page-padding-tab: 0 30px;
  --page-padding-mo: 0 20px;
}

.block {
  display: block !important;
}

.none {
  display: none !important;
}

.none-pc {
  display: none !important;
}

.none-mo {
  display: block !important;
}

.flex {
  display: flex !important;
}

.inline {
  display: inline !important;
}

.visible {
  visibility: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}

.max-h100vh {
  max-height: 100vh !important;
}

.padding-top-20px {
  padding-top: 20px !important;
}

.underline {
  text-decoration: underline !important;
}

.bold {
  font-weight: bold !important;
}

.br-pc {
  display: block;
}

.br-mo {
  display: none;
}

@media (max-width: 1023px) {
  .br-pc {
    display: none !important;
  }

  .br-mo {
    display: block !important;
  }

  .none-pc {
    display: block !important;
  }

  .none-mo {
    display: none !important;
  }
}


/* ======================================================== */

.section-one {
  padding: 100px 0;
}

.section-one__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
}


.section__txt-title,
.section__txt-sub {
  font-size: 35px;
  line-height: 35px;
  letter-spacing: -0.5px;
  font-weight: 600;
  text-align: center;
}

.color-green {
  color: #13AC67;
}

.bg-lightgreen {
  background-color: #f4f9f7;
  ;
}

.section__txt-sub {
  padding-top: 20px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
}

.section__txt-line {
  margin: 0 auto;
  margin-bottom: 30px;
  width: 3px;
  height: 80px;
  background: #3DA874;
}

@media (max-width: 1023px) {
  .section-one {
    padding: 65px 0;
  }

  .section-one__con {
    padding: var(--page-padding-mo);
    max-width: 600px;
  }

  .section__txt-title {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -1px;
    /* font-weight: 700; */
  }

  .section__txt-sub {
    padding-top: 20px;
    /* font-weight: 400; */
  }

  .section__txt-line {
    /* margin: 0 auto; */
    margin-bottom: 20px;
    width: 3px;
    height: 60px;
    /* background: #3DA874; */
  }
}

/* ======================================================== */
/* Header  */

.header {
  height: var(--header-height);
  /* 평상시에 height를 초과한 서브메뉴가 안보이게 hidden */
  position: fixed;
  width: 100%;
  z-index: 500;
}

.header * {
    color: #fff;
} 

.header-changed {
    background-color: #fff;
  box-shadow: 0 1px 2px #cfcfcf,
              0 0px 3px #888888;
}

.header-changed * {
    color: #111;
}


.header__con {
  height: var(--header-height);
  max-width: var(--header-width);
  width: 100%;
  margin: 0 auto;
  padding: var(--page-padding-pc);
  display: flex;
  justify-content: space-between;
  color: #111;
  z-index: 300 !important;
}

.header__logo-con {
  height: 100%;
  display: flex;
  align-items: center;
}

/* .header__logo img {
  width: 120px;
} */


.header__logo-con > li {
  height: 100%; 
  font-size: 23px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 28px;

}

.header__logo-con > li > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__logo {
    height: 100%;
    display: flex;
    align-items: center;
}

.header__logo img {
  height: 40%;
  object-fit: contain;
  /* margin-right: 10px; */
}


.header__category-con,
.header__contact-wrap {
  height: 100%;
  display: flex;
  align-items: center;
}

.header__category-con > li,
.header__contact-wrap > li {
  height: 100%;
  /* width: 90px; */
  margin: 0 20px;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__category-con > li > a,
.header__contact-wrap > li > a {
  /* height: 100%; */
}

.header__dropdown {
  position: relative;
}

.header__dropdown > ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  /* transform: translateX(-50%); */
  width: 150px;
  /* height: 45px; */
  background-color: #fff;
  border: 2px solid #e4e1e1;
  border-radius: 5px;
}

.header__dropdown:hover > a {
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 2px solid #dcc596;
}

.header__dropdown:hover > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header__dropdown:hover > ul > li > a {
  display: block;
  padding: 15px 0;
  font-size: 13px;
  font-weight: 400;
  color: #393939 !important;
  letter-spacing: -1px;
}

.header__hamburger {
  display: none;
}


.header-mo {
    display: none;
}

.header-block {
  height: var(--header-height);
}
  

@media (max-width: 1023px) {

  .header-unclick {
    height: 60px !important;
  }
  

  /* .header-click {
    height: 90% !important;
  } */

  .header__con {
    height: 60px;
    display: block;
    padding: var(--page-padding-mo);
  }

  .header__logo-con {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .header__logo img {
    margin-right: 10px;
  }
  

  .header__logo > a {
    font-size: 20px;
  }

  .header__hamburger {
    display: block;
  }

  .header__hamburger img {
    width: 24px;
  }


  .header__category-con,
  .header__contact-wrap {
    display: none;
  }

  .header-mo {
    display: block;
    position: fixed;
    width: 80%;
    height: 100vh;
    left: -110%;
    top: 0;
    z-index: 999;
    transition: left .4s ease-in;
    background-color: #fff;
  }

  .left-0 {
    left: 0;
  }

  .header-mo__category-con {
      margin-top: 50px;
  }

  .header-mo__category-con > li > a {
      width: 100%;
      border-bottom: 1px solid #d5d5d5;
  }

  .header-mo__category-con > li:last-child > a {
      border: none;
  }

  .header-mo__category-con > li > a {
      display: inline-block;
      padding: 18px 30px;
      font-size: 14px;
      font-weight: 400;
  }

  .header-mo__dropdown > ul {
    display: none;
  }

  .header-mo__dropdown:hover > ul {
    display: block;
  }

  .header-mo__dropdown > ul > li > a {
    display: inline-block;
    padding: 5px 30px;
    font-size: 14px;
    font-weight: 400;
    color: #4c4c4c;

  }

  .bg-gray {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 888;
      background-color: rgba(0, 0, 0, 0.8);
  }
}

/* ======================================================== */

.intro-hero {
  /* height: calc(100vh - var(--header-height)); */
  height: 100vh;
  position: relative;
}

.intro-hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.intro-hero__bg-gray {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.137);
}

.intro-hero__bg video {
  z-index: -1;
  width: 100%;
  height: 100vh;
  position: absolute;
  object-fit: cover;
}

.intro-hero__logo-wrap {
  position: absolute;
  top: 52%;
  left: 50%;
  width: 150px;
  transform: translate(-50%, -50%);  
}

.intro-hero__logo-wrap > img{
  width: 100%;
  animation: scaleup 1s ease-in;
}

@keyframes scaleup {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.intro-hero__features-con {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  width: 100%;
}

.intro-hero__features-con > li {
  width: 170px;
  margin: 10px 30px;
  padding: 10px 10px;
  
  display: flex;

  color: #fff;
  border-top: 1px solid #fff;
  opacity: 0.6;
  transition: opacity 0.5s ease-in;
}

.intro-hero__features-con > li:hover {
  opacity: 0.8;
}

.intro-hero__features-con > li > img {
  width: 30%;
}

.intro-hero__features-con > li > span {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 10%;

  font-size: 14px;
  font-weight: 300;
}


@media (max-width: 1023px) {
  .intro-hero__logo-wrap {
    /* position: absolute; */
    top: 45%;
    /* left: 50%; */
    width: 120px;
    /* transform: translate(-50%, -50%);   */
  }

  .intro-hero__features-con > li {
    width: 125px;
    margin: 10px 20px;
  }
}

/* ========================================================= */

.intro-company {
  padding-top: 300px;
  background-image: linear-gradient(to bottom, #070233 0%, #853BE3 35%, #853BE3 65%, #340b6a 100%);
  /* background-image: linear-gradient(to bottom, #1D0739 0%, #853BE3 100%); */
}

.intro-company > .section-one__con {
  padding-bottom: 150px;
}

.intro-company__flex-con {
  display: flex;
  justify-content: space-between;
}

.intro-company__txt-con {
  width: 430px;
  color: #fff;
  z-index: 1;
}

.intro-company__txt-title {
  width: 600px;
  font-size: 60px;
  line-height: 75px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.intro-company__txt-sub {
  margin-top: 60px;
  width: 300px;

  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  letter-spacing: -1px;
  opacity: 0.6;
}

.intro-company .btn-more {
  margin-top: 60px;
}

.btn-more {
  width: 180px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  font-weight: 700;
  color: #fff;

  border: 2px solid #B68A47;
  box-shadow: 4px 4px 2px #C4955E;
  cursor: pointer;
}

.intro-company__img-wrap01 {
  /* margin-top: -90px; */
  width: 350px;
  height: 440px;
}

.intro-company__img-wrap02 {
  /* margin-top: 60px; */
  width: 240px;
  height: 440px;
}

.intro-company__img-wrap01 > img,
.intro-company__img-wrap02 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 10px 10px 4px #2a2a2a;
}

.intro-comapny__video-wrap {
  margin-top: 120px;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 20px;
}

.intro-comapny__video-wrap > video {
  width: 100%;
  height: 100%;
}


@media (max-width: 1023px) {
  .intro-company {
    padding-top: 100px;
  }
  
  .intro-company__txt-con {
    width: 100%;
  }
  
  .intro-company__txt-title {
    width: 100%;
    font-size: 30px;
    line-height: 45px;
    word-break: keep-all;
  }
  
  .intro-company__txt-sub {
    margin-top: 30px;
    max-width: 400px;
    width: 100%;
  }

  .intro-company__img-wrap01,
  .intro-company__img-wrap02 {
    display: none;
  }

  .intro-comapny__video-wrap {
    margin-top: 100px;
    border-radius: 15px;
  }
}

/* ======================================================== */

.intro-contact {
  /* background-image: linear-gradient(to bottom, #790fa3 0%, #340b6a 100%); */
}

.intro-contact > .section-one__con {
  display: flex;
  justify-content: space-between;
}

.intro-contact__txt {
  width: 45%;
}

.intro-contact__txt-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -.5px;
  color: #fff;
}

.intro-contact__txt-sub {
  margin-top: 30px;
}

.intro-contact__txt-sub > li {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  color: #fff;
  opacity: 0.7;
}

.intro-contact .btn-more {
  margin-top: 60px;
  
  background-color: #232323;
}

.intro-contact__img-wrap {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-contact__img-wrap > img {
  width: 60%;
  /* aspect-ratio: 16/10; */
  object-fit: cover;
  border-radius: 20px;
}

@media (max-width: 1023px) {
  .intro-contact > .section-one__con {
    flex-direction: column;    
  }

  .intro-contact__txt {
    width: 100%;
    text-align: center;
  }

  .intro-contact__txt-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
  }

  .intro-contact__txt-sub > li {
    margin-bottom: 13px;
    font-size: 16px;
    line-height: 24px;
  }

  .intro-contact .btn-more {
    margin: 0 auto;
    margin-top: 60px; 
    /* background-color: #000; */
  }

  .intro-contact__img-wrap {
    margin: 0 auto;
    margin-top: 60px;
    width: 80%;
  }

  .intro-contact__img-wrap > img {
    aspect-ratio: 16/12;
    object-fit: contain;
  }
}

/* ======================================================== */

.intro-menu {
  background-color: #fff;

  border-radius: 180px;
  padding: 220px 0;
}


.intro-menu__txt-con {
  display: flex;
}

.intro-menu__txt-left-con {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro-menu__txt-left-sub {
  font-size: 20px;
  font-weight: 300;  
}
.intro-menu__txt-left-title {
  font-size: 50px;
  line-height: 65px;
  font-weight: 600;
}

.intro-menu__txt-right-con {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro-menu__txt-right-item:not(:first-child){
  margin-top: 40px;
}

.intro-menu__txt-right-item > li {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -1px;
  cursor: pointer;
}

.intro-menu__selected {
  color: #fff;
  background-color: #321E00;
}

.intro-menu__txt-right-btn-con {
  margin-left: -5px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.intro-menu__txt-right-btn-con > ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.intro-menu__txt-right-btn-con > ul > li {
  padding: 15px 20px;
  margin: 5px;

  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  
  border: 1px solid #000;
  border-radius: 50px;
  cursor: pointer;
}

.intro-menu__txt-right-item > div {
  margin-top: 15px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: -1px;
}

.intro-menu .btn-more {
  margin-top: 40px;
  color: #321E00;
}

@media (max-width: 1023px) {
  .intro-menu {  
    border-radius: 100px;
    padding: 140px 0;
  }
  
  .intro-menu__txt-con {
    flex-direction: column;
  }
  
  .intro-menu__txt-left-sub {
    font-size: 14px;
  }
  .intro-menu__txt-left-title {
    margin-top: 5px;
    font-size: 35px;
    line-height: 45px;
  }
  
  .intro-menu__txt-right-item{
    margin-top: 30px;
  }
  
  .intro-menu__txt-right-item > li {
    font-size: 14px;
  }
  
  .intro-menu__txt-right-btn-con {
    margin-left: -5px;
    margin-top: 10px;
  }

  .intro-menu__txt-right-btn-con > ul > li {
    padding: 10px 15px;
    margin: 5px;
  
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -1px;
  }
  
  .intro-menu__txt-right-item > div {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  }
}

/* ========================================================= */

.intro-menu__slick-con {
  margin: 0 auto;
  margin-top: 180px;
  width: 100%;
  height: var(--slick-height);
  position: relative;
}

.intro-menu__slick-con * {
  pointer-events: auto;
}

.intro-menu__slick {
  width: 100%;
  height: 100%;
}

/* li와 동일 */
.intro-menu__slick .slick-slide {
  height: var(--slick-height);
  padding: 0px 10px;
}

.intro-menu__slick .slick-slide>div {
  height: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 30px;
  cursor: pointer;
  
  position: relative;
}

.intro-menu__slick .slick-slide>div img {
  width: 100%;
  height: var(--slick-height);
  /* height: calc(var(--slick-height) * 0.50) !important; */
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 2px 2px 3px #afafaf;

}

.intro-menu__slick .slick-slide ul {
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%);
}

.intro-menu__slick .slick-slide ul>li:nth-child(1) {
  width: 300px;
  font-size: 35px;
  line-height: 35px;
  font-weight: 600;
  letter-spacing: -1px;
  text-align: center;
  text-shadow: 2px 2px 2px #828282;
  color: #fff;

  font-family: 'NanumMyeongjo', sans-serif;
}


.intro-menu__slick .slick-dots {
  bottom: -40px;
}

.intro-menu__slick .slick-dots li button:before,
.intro-menu__slick .slick-active button:before {
  color: #000 !important;
}

.intro-menu__slick-arrow-con {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.intro-menu__slick-arrow-con>div {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  box-sizing: border-box;
  cursor: pointer;
}

.intro-menu__slick-arrow-con>div:nth-of-type(1) {
  left: -40px;
}

.intro-menu__slick-arrow-con>div:nth-of-type(2) {
  right: -40px;
  text-align: right;
}

.intro-menu__slick-arrow-con>div>img {
  width: 20px;
}

@media (max-width: 1023px) {
  .intro-menu__slick-con {
    margin-top: 120px;
    width: 97%;
  }

  .intro-menu__slick .slick-slide ul {
    padding: 0px 0px;
  }

  .intro-menu__slick .slick-dots {
    display: none !important;
  }

  .intro-menu__slick-arrow-con>div:nth-of-type(1) {
    left: -15px;
  }

  .intro-menu__slick-arrow-con>div:nth-of-type(2) {
    right: -15px;
    text-align: right;
  }

  .intro-menu__slick-arrow-con>div>img {
    width: 15px;
  }
}


/* ======================================================== */

.service__btn-con {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.service__btn-con select {
  padding: 17px 25px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  border: 1px solid #cccccc;
  border-radius: 30px;
  background-color: #fff !important;
}

/* product */
.service__btn-items {
  padding: 0 25px;
  display: flex;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  /* padding: 0 25px;
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 30px; */
}

.service__btn-items > ul {
  display: flex;
  justify-content: center;
}

.service__btn-items > ul > li {
  padding: 20px 25px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: -1px;
  cursor: pointer;
}

.service__btn-after {
  font-weight: 700 !important;
}

@media (max-width: 1023px) {
  .service__btn-con {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .service__btn-con select {
    padding: 12.25px 15px;
    font-size: 12px;
    line-height: 12px;
    /* font-weight: 700; */
    /* border: 1px solid #cccccc; */
    /* border-radius: 30px; */
    /* background-color: #fff !important; */
  }

  .service__btn-items {
    padding: 0 10px;
    flex-direction: column;

    /* padding: 0 15px;
    border-radius: 20px;
    flex-direction: column; */
  }
  
  .service__btn-items > ul > li {
    padding: 10px 20px;
    font-size: 12px;
    line-height: 12px;
  }
  
  .service__btn-after {
    font-weight: 700 !important;
  }

}

/* ======================================================== */

.company-ceo__title {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -.5px;
}

.company-ceo__img-wrap {
  position: relative;
  margin-top: 60px;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 25px;
  background-image: linear-gradient(to right, #ffa597 0%, #ff82b4 100%);
  box-shadow: 2px 2px 4px #9e9e9e;
}

.company-ceo__img-wrap > img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 90%;
  object-fit: contain;
  /* object-position: 0% 0%; */
  z-index: 2;
}

.company-ceo__img-wrap > span {
  position: absolute;
  width: 70%;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.company-ceo__img-wrap > span > img {
  width: 100%;
}

.company-ceo__txt {
  margin-top: 40px;
  width: 100%;

  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: -.5px;
  word-break: keep-all;  
}

@media (max-width: 1023px) {
  .company-ceo {
    margin-top: 40px;
    padding: var(--page-padding-mo);
  }
 
  .company-ceo__title {
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -.5px;
  }

  .company-ceo__img-wrap {
    margin-top: 30px;
    border-radius: 20px;
    box-shadow: 2px 2px 3px #9e9e9e;
  }


  .company-ceo__txt {
    margin-top: 40px;

    font-size: 14px;
    line-height: 24px;
  }
}

/* ========================================================= */

.company-history__con {
  margin-top: 200px;
  display: flex;
}

.company-history__txt {
  padding: 80px 0;
  width: 53%;
  height: auto;
  background-image: linear-gradient(53deg, #BA8D61 0%, #926438 100%);
  border-top-right-radius: 90%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.company-history__txt-con {
}

.company-history__txt-title {
  margin-top: 30px;
  font-size: 40px;
  line-height: 60px;
  font-weight: 100;
  color: #fff;
}
.company-history__txt-title > span {
  font-weight: 500;
}

.company-history__txt-sub {
  font-size: 16px;
  line-height: 16px;
  font-weight: 100;
  color: #fff;
}
.company-history__txt-sub > span {
  font-weight: 400;
}

.company-history__list-con {
  padding: 60px 60px;
  width: 47%;
}

.company-history__list-con > li {
  padding-left: 20px;
  border-left: 2px solid #0000001A;
}
.company-history__list-con > ul {
  padding-left: 20px;
  /* border-left: 2px solid #0000001A; */
  border-left: 2px solid #0000002A;
}


.company-history__list-con > ul > li {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #321E00;

  position: relative
}

.company-history__list-con > ul > li::before {
  content: "";
  display: block;

  padding: 5px;
  border: 2px solid #926438;
  border-radius: 50px;
  background-color: #fff;

  position: absolute; /* 원본에 relative 필수 */
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
}

.company-history__list-con > ul > ul > li {
  padding: 3px 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 400;
}

.company-history__list-con > ul > ul > li:first-child {
  padding-top: 15px;
}
.company-history__list-con > ul > ul > li:last-child {
  padding-bottom: 30px;
}

@media (max-width: 1023px) {
  .company-history__con {
    flex-direction: column;
  }
  
  .company-history__txt {
    padding: 100px 20px;
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 80%;
    justify-content: flex-start;
  }

  .company-history__txt-title {
    margin-top: 20px;
    font-size: 30px;
    line-height: 45px;
  }

  .company-history__txt-sub {
    font-size: 14px;
    line-height: 14px;
  }

  .company-history__list-con {
    padding: 40px 30px;
    width: 100%;
  }
  
  .company-history__list-con > li {
    line-height: 10px;
  }

  .company-history__list-con > ul > ul > li {
    padding: 4px 0;
    font-size: 14px;
    line-height: 18px;
  }
  
  .company-history__list-con > ul > ul > li:first-child {
    padding-top: 10px;
  }
  .company-history__list-con > ul > ul > li:last-child {
    padding-bottom: 20px;
  }
}



/* ======================================================== */

.service-detail {
  background-color: #fff;
  border-radius: 180px;
}


.service-detail__txt-con {
  display: flex;
  justify-content: space-between;
}

.service-detail__txt-left-con {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.service-detail__txt-left-item:not(:first-child){
  margin-top: 40px;
}

.service-detail__txt-left-item > li {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -1px;
}

.service-detail__txt-left-btn-con {
  margin-left: -5px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.service-detail__txt-left-btn-con > ul {
  display: flex;
  flex-direction: row;
}
.service-detail__txt-left-btn-con > ul > li {
  padding: 15px 20px;
  margin: 5px;

  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  
  border: 1px solid #000;
  border-radius: 50px;
}

.service-detail__txt-left-item > div {
  margin-top: 15px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: -1px;
}

.service-detail__txt-right-con {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;


}

.service-detail__video-wrap {
  width: 100%;
  aspect-ratio: 16/9;
  border: 4px solid transparent;
  border-image: linear-gradient(to right, rgb(194, 83, 4) 0%, rgb(255, 185, 72) 100%);
  border-image-slice: 1;

  position: relative;
}

.service-detail__video-wrap video {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}


@media (max-width: 1023px) {
  .service {  
    padding: 30px 0;
  }
  
  .service-detail {
    padding: 0;
  }

  .service-detail__txt-con {
    flex-direction: column;
  }
  
  .service-detail__txt-left-con {
    width: 100%;
  }

  .service-detail__txt-left-item{
    margin-top: 30px;
  }
  
  .service-detail__txt-left-item > li {
    font-size: 14px;
  }
  
  .service-detail__txt-left-btn-con {
    margin-left: -5px;
    margin-top: 10px;
  }
  .service-detail__txt-left-btn-con > ul {
    flex-wrap: wrap;
  }
  .service-detail__txt-left-btn-con > ul > li {
    padding: 10px 15px;
    margin: 5px;
  
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -1px;
  }
  
  .service-detail__txt-left-item > div {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .service-detail__txt-right-con {
    margin-top: 60px;
    width: 100%;
  }
}

/* ======================================================== */

.service-img {
  padding-top: 120px;
}

.service-img__up-grid-con,
.service-img__down-grid-con {
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-left: -5px;
}

.service-img__bg-gray {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.service-img__up-grid-con > div {
  margin: 4px 4px;
  width: calc( (100% - 3 * 8px) / 3 );
  aspect-ratio: 1;
  position: relative;
}

.service-img__up-grid-con > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.service-img__up-grid-con > div > span,
.service-img__down-grid-con > div > span {
  position: absolute;
  top: 10%;
  left: 10%;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  z-index: 3;
}

.service-img__down-grid-con > div {
  margin: 8px 4px;
  width: calc( (100% - 2 * 8px) / 2 );
  aspect-ratio: 5/3;
  position: relative;
}

.service-img__down-grid-con > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

@media (max-width: 1023px) {
  .service-img {
    margin-top: 80px;
    padding-top: 0;

    border-top: 2px dashed #d9d9d9;
  }
  .service-img__up-grid-con {
    padding-top: 80px;
  }

  .service-img__up-grid-con,
  .service-img__down-grid-con {
    flex-direction: column;
    margin-top: 0;
    margin-left: 0;
  }
  .service-img__up-grid-con > div,
  .service-img__down-grid-con > div {
    margin: 0;
    width: 100%;
    height: 220px;
    margin-bottom: 5px;
  }
  .service-img__up-grid-con > div > span,
  .service-img__down-grid-con > div > span {
    top: 7%;
    left: 7%;
    font-size: 22px;

  }
}


/* ========================================================= */

.footer {
  /* margin-top: 30px; */
  padding: 20px 0;

  /* custom */
  background: #F7F7F7;
  color: rgba(0,0,0,0.3);

}

.footer__con {
  /* padding: var(--page-padding-pc); */
  margin: 0 auto;
  width: 100%;
  /* max-width: var(--page-width); */

  /* display: flex; */
  position: relative;
}

.footer__logo {
  margin: 10px 8vw 30px 8vw;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: white;

  font-weight: bold;
}

.footer__con > hr {
  background: rgba(0,0,0,0.1);
  height: 2px;
  border: 0;
}

.footer__logo > img {
  width: 100%;
  opacity: 0.3;
}

.footer__txt-con {
  margin: 30px 8vw;
}

.footer__txt01 {
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 22px;
}

.footer__txt02 {
  font-size: 14px;
}

.footer__icon-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 30px;
}
.footer__icon-con > a {
  margin-left: 15px;
  width: 40px;
}
.footer__icon-con > a > img {
  width: 100%;
  opacity: 0.3;
}

.footer__law-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 0px;
  font-size: 13px;
}

@media (max-width: 1023px) {
  .footer {
      /* padding-bottom: 50px; */
  }
  .footer__con {
      /* padding: var(--page-padding-tab); */
      flex-direction: column;
  }
  .footer__logo {
      /* margin-bottom: 10px; */
      justify-content: left;
      font-size: 24px;
  }
  .footer__txt01 {
      font-size: 12px;
      line-height: 20px;
  }
  
  .footer__txt02 {
      font-size: 14px;
  }

  .footer__icon-con {
    right: 20px;
    bottom: auto;
    top: 0;
  }
  .footer__law-con {
    right: 20px;
    bottom: -30px;
    font-size: 12px;
  }
}

/* ========================================================= */

.license__con {
    padding: var(--page-padding-pc);
    margin: 0 auto;
    width: 100%;
    max-width: var(--page-width);
  } 
  
  .license__grid-con { 
    margin: 100px 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .license__grid-item {
    margin: 30px 10px 30px 10px;
    width: calc( (100% - 2 * 20px) / 2 );
    display: flex;
    flex-direction: column;
    /* cursor: pointer; */
    position: relative;
  }
  
  .license__source-name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #428ef9;
    cursor: pointer;
  }
  
  .license__source-url {
    letter-spacing: -1px;
    opacity: 0.7;
    font-weight: 400;
    color: darkblue;
    text-decoration: underline;
  }
  
  .license__source-type {
    letter-spacing: -1px;
    opacity: 0.7;
  }
  
  
  .license__source-writer {
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 22px;
  }
  
  .license__txt-item {
    padding: 30px 0;
    border-top: 1px solid #afafaf;
  }
  
  .license__txt-title {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }
  
  .license__txt-sub {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
    color: #222222;
  }
  
  
  @media (max-width: 1023px) {
    .license__con {
      padding: var(--page-padding-tab);
    } 
  
    .license__txt-sub {
      font-size: 18px;
    }
  }
  
  @media (max-width: 767px) {
    .license__con {
      padding: var(--page-padding-mo);
    }
  
    .license__grid-item {
      margin: 15px 0px 15px 0px;
      margin-bottom: 15px;
      width: calc( (100% - 2 * 10px) / 2 );
    }
  
    .license__grid-item:nth-child(2n-1) {
      margin-right: 20px;
    }
  
    .license__source-name {
      font-size: 18px;
    }
  
    
    .license__source-type {
      font-size: 15px;
    }
  
    .license__source-writer {
      font-size: 15px;
      font-weight: 400;
    }
  
   
  }
  @media (max-width: 480px) {
  }
  
  